import { show } from '@/services/modal.js';

export default function confirmDelete(type, name) {
  return new Promise(((resolve) => {
    show(
      () => import(/* webpackChunkName: "confirmDelete" */ './EzConfirmDelete.vue'),
      {
        type,
        name,
      },
      {
        name: 'confirm-delete-dialog',
        height: 'auto',
        classes: ['dialog', 'is-service-funnels'],
      },
      {
        'before-close': (e) => {
          resolve(e.params);
        },
      },
    );
  }));
}
