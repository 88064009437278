<script>
import EzPopupContainer from '@dmant/ez-ui-components/src/components/popup-container';
import { hide } from '@/services/modal.js';
import i18n from '@/i18n';

export default {
  name: 'EzfConfirmDelete',
  components: { EzPopupContainer },
  props: {
    type: String,
    name: String,
  },
  methods: {
    onClick(isDelete) {
      hide('confirm-delete-dialog', isDelete);
    },
  },
  computed: {
    params() {
      const typeTranslateKey = `confirmDelete.types.${this.type}`;
      const type = i18n.te(typeTranslateKey) ? i18n.t(typeTranslateKey) : this.type;
      const { name } = this;
      return {
        type,
        name,
      };
    },
    description() {
      const { params } = this;
      let typeTranslateKey = `confirmDelete.descriptions.${this.type}`;
      if (!i18n.te(typeTranslateKey)) {
        typeTranslateKey = 'confirmDelete.description';
      }
      return i18n.t(typeTranslateKey, params);
    },
    t() {
      const { params } = this;
      return {
        title: i18n.t('confirmDelete.title', params),
        cancel: i18n.t('confirmDelete.buttons.cancel'),
        delete: i18n.t('confirmDelete.buttons.delete'),
      };
    },
  },
};
</script>

<template>
  <ez-popup-container
    service-name="funnels"
    @close="onClick(false)"
    icon-header="trash"
  >
    <template slot="header">
      {{ t.title }}
    </template>

    <p>{{ description }}</p>

    <template slot="footer">
      <ez-button-group justify="end">
        <ez-button type="secondary" @click="onClick(false)">
          {{ t.cancel }}
        </ez-button>
        <ez-button type="danger" @click="onClick(true)">
          {{ t.delete }}
        </ez-button>
      </ez-button-group>
    </template>
  </ez-popup-container>
</template>
